<template>
  <div>
    <b-overlay :show="show">
      <b-row>
        <b-col
          v-if="camposDeshabilitados"
          cols="12"
        >
          <h3 class="text-primary">
            Detalles del Accidente
            <hr>
          </h3>
        </b-col>
        <b-col cols="12">
          <b-tabs
            content-class="pt-1"
            fill
            pills
          >
            <b-tab
              v-for="(poste, index) in coordinacion.postes"
              :key="index"
              :title="`POSTE #${index + 1} - ${poste.numeroPoste}`"
              :active="index === 0"
            >
              <b-row>
                <b-col cols="12">
                  <b-tabs
                    content-class="pt-1"
                    fill
                    pills
                  >
                    <b-tab
                      v-for="(detalle, detalleIndex) in 6"
                      :key="detalleIndex"
                      :title="titulosDetalles[detalleIndex]"
                    >
                      <template v-if="detalleIndex === 0">
                        <b-row>
                          <b-col
                            cols="12"
                            sm="3"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Poste Referencia
                              </h6>
                              <b-form-input
                                v-model="coordinacion.detalleAccidente[index].datosPoste.poste"
                                disabled
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="5"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Tipo de Poste
                              </h6>
                              <v-select
                                v-model="coordinacion.detalleAccidente[index].datosPoste.tipoPoste"
                                label="nombre"
                                :disabled="camposDeshabilitados"
                                :options="tiposPoste"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Daño del Poste
                              </h6>
                              <v-select
                                v-model="coordinacion.detalleAccidente[index].datosPoste.dañoPoste"
                                label="nombre"
                                :disabled="camposDeshabilitados"
                                :options="daños"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="8"
                          >
                            <h6 class="text-primary">
                              Observaciones del Poste
                            </h6>
                            <b-form-group>
                              <b-form-textarea
                                v-model="coordinacion.detalleAccidente[index].datosPoste.observacionesPoste"
                                rows="2"
                                :disabled="camposDeshabilitados"
                                no-resize
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <h6 class="text-primary">
                              Fotografía del Poste
                            </h6>
                            <template v-if="!deberMostrarImagen(index, 'datosPoste')">
                              <!-- Aquí colocas tu componente de imagen -->
                              <imagen
                                :key="'fotografia-poste'"
                                :leyenda="'fotografia-poste'"
                                @cargar-file="imagenPoste"
                              />
                            </template>
                            <template v-else>
                              <!-- Aquí colocas tu botón que abre el modal -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-1
                                style="height: 70px"
                                block
                                variant="gradient-primary"
                                @click="mostrarImagen(index, 'datosPoste')"
                              >
                                <feather-icon icon="EyeIcon" />
                              </b-button>
                            </template>
                          </b-col>
                        </b-row>
                      </template>
                      <template v-else-if="detalleIndex === 1">
                        <b-row>
                          <b-col
                            cols="12"
                            sm="5"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Tamaño de Brazo
                              </h6>
                              <v-select
                                v-model="coordinacion.detalleAccidente[index].datosBrazo.tamañoBrazo"
                                label="nombre"
                                :disabled="camposDeshabilitados"
                                :options="tamañosBrazo"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="3"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Cantidad de Brazos
                              </h6>
                              <v-select
                                v-model="coordinacion.detalleAccidente[index].datosBrazo.cantidadBrazo"
                                label="nombre"
                                :disabled="camposDeshabilitados"
                                :options="tamañosBrazo"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Daño del Brazo
                              </h6>
                              <v-select
                                v-model="coordinacion.detalleAccidente[index].datosBrazo.dañoBrazo"
                                label="nombre"
                                :disabled="camposDeshabilitados"
                                :options="daños"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="8"
                          >
                            <h6 class="text-primary">
                              Observaciones del Brazo
                            </h6>
                            <b-form-group>
                              <b-form-textarea
                                v-model="coordinacion.detalleAccidente[index].datosBrazo.observacionesBrazo"
                                rows="2"
                                :disabled="camposDeshabilitados"
                                no-resize
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <h6 class="text-primary">
                              Fotografía del Brazo
                            </h6>
                            <template v-if="!deberMostrarImagen(index, 'datosBrazo')">
                              <!-- Aquí colocas tu componente de imagen -->
                              <imagen
                                :key="'foto-brazo'"
                                :leyenda="'foto-brazo'"
                                @cargar-file="imagenBrazo"
                              />
                            </template>
                            <template v-else>
                              <!-- Aquí colocas tu botón que abre el modal -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-1
                                style="height: 70px"
                                block
                                variant="gradient-primary"
                                @click="mostrarImagen(index, 'datosBrazo')"
                              >
                                <feather-icon icon="EyeIcon" />
                              </b-button>
                            </template>
                          </b-col>
                        </b-row>
                      </template>
                      <template v-else-if="detalleIndex === 2">
                        <b-row>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Cantidad
                              </h6>
                              <v-select
                                v-model="coordinacion.detalleAccidente[index].datosLampara.cantidadLampara"
                                label="nombre"
                                :disabled="camposDeshabilitados"
                                :options="tramos"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Potencia
                              </h6>
                              <v-select
                                v-model="coordinacion.detalleAccidente[index].datosLampara.potenciaLampara"
                                label="nombre"
                                :disabled="camposDeshabilitados"
                                :options="potencias"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Daños
                              </h6>
                              <v-select
                                v-model="coordinacion.detalleAccidente[index].datosLampara.dañoLampara"
                                label="nombre"
                                :disabled="camposDeshabilitados"
                                :options="daños"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="8"
                          >
                            <h6 class="text-primary">
                              Observaciones de la Lámpara
                            </h6>
                            <b-form-group>
                              <b-form-textarea
                                v-model="coordinacion.detalleAccidente[index].datosLampara.observacionesLampara"
                                rows="2"
                                :disabled="camposDeshabilitados"
                                no-resize
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <h6 class="text-primary">
                              Fotografía de la Lámpara
                            </h6>
                            <template v-if="!deberMostrarImagen(index, 'datosLampara')">
                              <!-- Aquí colocas tu componente de imagen -->
                              <imagen
                                :key="'foto-lampara'"
                                :leyenda="'foto-lampara'"
                                @cargar-file="imagenLampara"
                              />
                            </template>
                            <template v-else>
                              <!-- Aquí colocas tu botón que abre el modal -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-1
                                style="height: 70px"
                                block
                                variant="gradient-primary"
                                @click="mostrarImagen(index, 'datosLampara')"
                              >
                                <feather-icon icon="EyeIcon" />
                              </b-button>
                            </template>
                          </b-col>
                        </b-row>
                      </template>
                      <template v-else-if="detalleIndex === 3">
                        <b-row>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Metros Aproximados
                              </h6>
                              <v-select
                                v-model="coordinacion.detalleAccidente[index].datosTriplex.metroTriplex"
                                label="nombre"
                                :disabled="camposDeshabilitados"
                                :options="metros"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Cantidad de Tramos
                              </h6>
                              <v-select
                                v-model="coordinacion.detalleAccidente[index].datosTriplex.cantidadTriplex"
                                label="nombre"
                                :disabled="camposDeshabilitados"
                                :options="tramos"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Daño
                              </h6>
                              <v-select
                                v-model="coordinacion.detalleAccidente[index].datosTriplex.dañoTriplex"
                                label="nombre"
                                :disabled="camposDeshabilitados"
                                :options="daños"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="8"
                          >
                            <h6 class="text-primary">
                              Observaciones del Triplex
                            </h6>
                            <b-form-group>
                              <b-form-textarea
                                v-model="coordinacion.detalleAccidente[index].datosTriplex.observacionesTriplex"
                                rows="2"
                                :disabled="camposDeshabilitados"
                                no-resize
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <h6 class="text-primary">
                              Fotografía del Triplex
                            </h6>
                            <template v-if="!deberMostrarImagen(index, 'datosTriplex')">
                              <!-- Aquí colocas tu componente de imagen -->
                              <imagen
                                :key="'foto-triplex'"
                                :leyenda="'foto-triplex'"
                                @cargar-file="imagenTriplex"
                              />
                            </template>
                            <template v-else>
                              <!-- Aquí colocas tu botón que abre el modal -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-1
                                style="height: 70px"
                                block
                                variant="gradient-primary"
                                @click="mostrarImagen(index, 'datosTriplex')"
                              >
                                <feather-icon icon="EyeIcon" />
                              </b-button>
                            </template>
                          </b-col>
                        </b-row>
                      </template>
                      <template v-else-if="detalleIndex === 4">
                        <b-row>
                          <b-col
                            cols="12"
                            sm="6"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Accesorios Adicionales
                              </h6>
                              <v-select
                                v-model="coordinacion.detalleAccidente[index].datosAccesorios.accesoriosAdicionales"
                                label="nombre"
                                multiple
                                :options="accesorios"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="3"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Daño
                              </h6>
                              <v-select
                                v-model="coordinacion.detalleAccidente[index].datosAccesorios.daños"
                                label="nombre"
                                :disabled="camposDeshabilitados"
                                :options="daños"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="3"
                          >
                            <b-form-group>
                              <h6
                                class="text-primary"
                              >
                                Número Reconexiones
                              </h6>
                              <b-form-spinbutton
                                v-model="coordinacion.detalleAccidente[index].datosReconexiones.cantidadReconexiones"
                                :disabled="camposDeshabilitados"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="8"
                          >
                            <h6 class="text-primary">
                              Observaciones de Accesorios
                            </h6>
                            <b-form-group>
                              <b-form-textarea
                                v-model="coordinacion.detalleAccidente[index].datosAccesorios.observacionesAccesorios"
                                rows="2"
                                :disabled="camposDeshabilitados"
                                no-resize
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <h6 class="text-primary">
                              Fotografía de Accesorios
                            </h6>
                            <template v-if="!deberMostrarImagen(index, 'datosAccesorios')">
                              <!-- Aquí colocas tu componente de imagen -->
                              <imagen
                                :key="'foto-accesorios'"
                                :leyenda="'foto-accesorios'"
                                @cargar-file="imagenAccesorios"
                              />
                            </template>
                            <template v-else>
                              <!-- Aquí colocas tu botón que abre el modal -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-1
                                style="height: 70px"
                                block
                                variant="gradient-primary"
                                @click="mostrarImagen(index, 'datosAccesorios')"
                              >
                                <feather-icon icon="EyeIcon" />
                              </b-button>
                            </template>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="8"
                          >
                            <h6 class="text-primary">
                              Observaciones de Reconexiones
                            </h6>
                            <b-form-group>
                              <b-form-textarea
                                v-model="coordinacion.detalleAccidente[index].datosReconexiones.observacionesReconexiones"
                                rows="2"
                                :disabled="camposDeshabilitados"
                                no-resize
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            sm="4"
                          >
                            <h6 class="text-primary">
                              Fotografía de Reconexiones
                            </h6>
                            <template v-if="!deberMostrarImagen(index, 'datosReconexiones')">
                              <!-- Aquí colocas tu componente de imagen -->
                              <imagen
                                :key="'foto-reconexiones'"
                                :leyenda="'foto-reconexiones'"
                                @cargar-file="fotografiaReconexiones"
                              />
                            </template>
                            <template v-else>
                              <!-- Aquí colocas tu botón que abre el modal -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-1
                                style="height: 70px"
                                block
                                variant="gradient-primary"
                                @click="mostrarImagen(index, 'datosReconexiones')"
                              >
                                <feather-icon icon="EyeIcon" />
                              </b-button>
                            </template>
                          </b-col>
                        </b-row>
                      </template>
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>
        <b-col
          v-if="!camposDeshabilitados"
          style="display: flex; justify-content: end"
        >
          <!--b-button @click="imprimirDatos">
          Imprimir
        </b-button-->
          <modal-ingreso-coordinadora
            :origen-data="'nuevoPoste'"
            :button-text="'Agregar Nuevo Poste'"
            :modal-titulo="'Agregar Nuevo Poste'"
            :placeholder="'Buscar Puntos de Iluminación...'"
            @agregar-poste="agregarPoste"
          />
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            style="margin-left: 10px"
            variant="primary"
            class="mt-2 mr-1"
            @click="actualizar"
          >
            Actualizar
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
    <div>
      <b-modal
        v-model="verDialogoDocumento"
        ok-only
        ok-variant="primary"
        ok-title="Aceptar"
        modal-class="modal-primary"
        centered
        size="lg"
        title="Detalle de la Imagen"
      >
        <b-card-text style="display: flex; justify-content: center">
          <b-img
            :src="urlImagen"
            fluid
            alt="Fluid-grow image')"
            style="max-height: 700px;"
          />
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BFormSpinbutton, BFormInput, BFormGroup, BRow, BCol, BFormTextarea, BTabs, BTab, BButton, BCardText, BImg, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getlistado } from '@/utils/catalogos'
import vSelect from 'vue-select'
import { getListadoAccesorios } from '@/utils/accesorios'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { mensajeInformativo } from '@/utils/mensajes'
import { getListadoMantenimiento } from '@/utils/mantenimiento/mantenimiento'
import modalIngresoCoordinadora from '@/components/ModaIngresoFormularios/ModalIngreso.vue'
import { updateCreateCoordinacion } from '@/utils/mantenimiento/coordinacionAseguradora/coordinacionAseguradoraUtils'
import { saveImagen } from '@/utils/imagenes'

export default {
  components: {
    BCol,
    BRow,
    BTab,
    BImg,
    BTabs,
    Imagen,
    BButton,
    vSelect,
    BOverlay,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSpinbutton,
    modalIngresoCoordinadora,
  },
  directives: {
    Ripple,
  },
  props: {
    coordinacion: {
      type: Object,
      required: false,
    },
    camposDeshabilitados: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      usuario: JSON.parse(localStorage.getItem('userData')),
      posteIndex: 0,
      show: false,
      verDialogoDocumento: false,
      urlImagen: '',
      tiposPoste: [],
      daños: [],
      tamañosBrazo: [],
      potencias: [],
      metros: [],
      tramos: [],
      accesorios: [],
      punto: '',
      titulosDetalles: [
        'Poste',
        'Brazo',
        'Lámpara',
        'Triplex',
        'Detalle de Accesorios y Reconexiones',
      ],
      datosPoste: {
        poste: '',
        tipoPoste: '',
        tamañoPoste: '',
        dañoPoste: '',
        observacionesPoste: '',
      },
      datosBrazo: {
        poste: '',
        cantidadBrazo: '',
        tamañoBrazo: '',
        dañoBrazo: '',
        observacionesBrazo: '',
      },
      datosLampara: {
        poste: '',
        cantidadLampara: '',
        potenciaLampara: '',
        codigoLampara: '',
        dañoLampara: '',
        observacionesLampara: '',
      },
      datosTriplex: {
        poste: '',
        cantidadTriplex: '',
        metroTriplex: '',
        dañoTriplex: '',
        observacionesTriplex: '',
      },
      datosAccesorios: {
        poste: '',
        accesoriosAdicionales: [],
        observacionesAccesorios: '',
      },
      datosReconexiones: {
        poste: '',
        cantidadReconexiones: '',
        observacionesReconexiones: '',
      },
      // Daños Luminaria
      fotoDañosPoste: '',
      fotoDañosBrazo: '',
      fotoDañosLampara: '',
      fotoDañosTriplex: '',
      fotoDañosAccesorios: '',
      fotoReconexiones: '',
    }
  },
  async created() {
    this.tiposPoste = await getlistado(1, 'Tipos de Poste', false, this.usuario)
    this.daños = getListadoMantenimiento(1)
    this.tamañosBrazo = await getlistado(14, 'Tipo Brazo', false, this.usuario)
    this.potencias = getListadoAccesorios(2)
    this.metros = getListadoAccesorios(4)
    this.tramos = getListadoAccesorios(3)
    this.accesorios = getListadoAccesorios(1)
    if (this.coordinacion) {
      this.cargarDatos(0)
    }
  },
  methods: {
    mostrarImagen(index, tipo) {
      const detalleActual = this.coordinacion.detalleAccidente[index]

      if (detalleActual && detalleActual[tipo] && detalleActual[tipo].foto !== null) {
        this.urlImagen = detalleActual[tipo].foto.url || ''
        this.verDialogoDocumento = true
      } else {
        console.error(`No hay fotos asociadas al detalleAccidente con índice ${index} y tipo ${tipo}.`)
      }
    },
    async agregarPoste(punto) {
      try {
        const body = { ...this.coordinacion }
        this.punto = punto

        const claveLocalStorage = `numeroPosteActual_${body.id}`
        localStorage.setItem(claveLocalStorage, punto.numeroPoste)

        body.postes.push(punto)

        const nuevoDetalle = {
          datosPoste: {
            poste: punto.numeroPoste,
            foto: null,
          },
          datosBrazo: {
            foto: null,
          },
          datosLampara: {
            foto: null,
          },
          datosTriplex: {
            foto: null,
          },
          datosAccesorios: {
            foto: null,
          },
          datosReconexiones: {
            foto: null,
          },
        }

        this.coordinacion.detalleAccidente.push({ ...nuevoDetalle })

        this.cargarDatos(body.postes.length - 1)
      } catch (error) {
        console.error('Error al agregar poste:', error)
      }
    },
    imprimirDatos() {
      console.log('puntoNumeroPoste', this.punto.numeroPoste)
      console.log('Detalle', this.coordinacion.detalleAccidente[1].datosPoste.poste)
      console.log('Punto', this.coordinacion.postes)
    },
    async actualizar() {
      try {
        this.show = true
        const body = { ...this.coordinacion }
        // eslint-disable-next-line no-unused-vars
        const urlBucket = `coordinacion-aseguradoras/${body.id}`
        const isMobile = window.screen.width <= 760

        const claveLocalStorage = `numeroPosteActual_${body.id}`
        const numeroPosteActual = localStorage.getItem(claveLocalStorage)

        const index = body.detalleAccidente.findIndex(detalle => detalle.datosPoste.poste === numeroPosteActual)

        if (index !== -1) {
          const detalleExistente = body.detalleAccidente[index]

          detalleExistente.datosPoste.foto = await saveImagen(urlBucket, this.localization, this.fotoDañosPoste, 'Fotografía Daños al Poste', this.punto.numeroPoste, this.coordinacion.usuarioCreacion, isMobile)
          detalleExistente.datosBrazo.foto = await saveImagen(urlBucket, this.localization, this.fotoDañosBrazo, 'Fotografía Daños al Brazo', this.punto.numeroPoste, this.coordinacion.usuarioCreacion, isMobile)
          detalleExistente.datosLampara.foto = await saveImagen(urlBucket, this.localization, this.fotoDañosLampara, 'Fotografía Daños a la Lámpara', this.punto.numeroPoste, this.coordinacion.usuarioCreacion, isMobile)
          detalleExistente.datosTriplex.foto = await saveImagen(urlBucket, this.localization, this.fotoDañosTriplex, 'Fotografía Daños al Triplex', this.punto.numeroPoste, this.coordinacion.usuarioCreacion, isMobile)
          detalleExistente.datosAccesorios.foto = await saveImagen(urlBucket, this.localization, this.fotoDañosAccesorios, 'Fotografía Daños a los Accesorios', this.punto.numeroPoste, this.coordinacion.usuarioCreacion, isMobile)
          detalleExistente.datosReconexiones.foto = await saveImagen(urlBucket, this.localization, this.fotoReconexiones, 'Fotografía de las Reconexiones', this.punto.numeroPoste, this.coordinacion.usuarioCreacion, isMobile)
        }

        await updateCreateCoordinacion(body, 1)
        mensajeInformativo('Actualizada', 'Coordinación Aseguradora Actualizada Correctamente!')
        this.show = false
      } catch (error) {
        this.show = false
        console.error('Error al actualizar coordinación:', error)
      }
    },
    deberMostrarImagen(index, tipo) {
      try {
        const detalleActual = this.coordinacion.detalleAccidente[index]

        if (detalleActual && detalleActual[tipo]) {
          return detalleActual[tipo].foto !== null
        }
        return false
      } catch (error) {
        console.error('Error al verificar si mostrar la imagen:', error)
        return false
      }
    },
    cargarDatos(posteIndex) {
      const startIndex = 6 * posteIndex

      if (this.coordinacion.detalleAccidente.length >= startIndex + 6) {
        this.datosPoste.poste = this.coordinacion.detalleAccidente[startIndex].datosPoste.poste
        this.datosPoste.tipoPoste = this.coordinacion.detalleAccidente[startIndex].datosPoste.tipoPoste
        this.datosPoste.dañoPoste = this.coordinacion.detalleAccidente[startIndex].datosPoste.dañoPoste
        this.datosPoste.observacionesPoste = this.coordinacion.detalleAccidente[startIndex].datosPoste.observacionesPoste
      }
    },
    imagenPoste(file) {
      this.fotoDañosPoste = file
    },
    imagenBrazo(file) {
      this.fotoDañosBrazo = file
    },
    imagenLampara(file) {
      this.fotoDañosLampara = file
    },
    imagenTriplex(file) {
      this.fotoDañosTriplex = file
    },
    imagenAccesorios(file) {
      this.fotoDañosAccesorios = file
    },
    fotografiaReconexiones(file) {
      this.fotoReconexiones = file
    },
  },
}

</script>

<style scoped>

</style>
